// SCSS 变量
$icon-font-path: "../fonts/bootstrap/";
$fa-font-path: "../fonts/font-awesome/";
$nav-width:                 220px;
$nav-back-color:            #373d41;
$green-dark:                #19aa8d;
$border-solid:              1px solid #ddd;
$border-dashed:             1px dashed #ddd;

// btn
$btn-primary-bg: 			      #2db7f5;
$btn-primary-border: 		    #2db7f5;
$label-primary-bg: 			    #2db7f5;
$font-size-base:            12px;

// form-control
$border-color-default:      #f0f0f0;
$border-radius-base:        2px;

$color-primary: 		        #2db7f5;
$navbar-width:              240px;

// a
$a-hover-color: #5e8d25; 

// Mixins
@mixin max-screen($res){
  @media only screen and ( max-width: $res )
  {
    @content;
  }
}
@mixin min-width($res){
  @media ( min-width: $res )
  {
    @content;
  }
}
@mixin loop-size-column($columns) {
  @for $i from 0 through $columns {
    .size-#{$i} {
    	width: percentage(($i / $columns)) !important;
    }
  }
}
@mixin loop-size-category($columns) {
  @for $i from 0 through $columns {
    .category-tree-#{$i+1} {
    	padding-left: 30px + 50*$i !important;
    }
  }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    filter: alpha(opacity=$opacity * 100);
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
}

@mixin transition($transition) {
    transition: $transition;
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
}